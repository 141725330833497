import axios from "axios";

export default {
    getComments(orderId) {
        return axios.get('/api/get_comments', { params: {'orderId': orderId} })
            .then(res => res.data);
    },

    addComment(orderId, textComment) {
        return axios.post('/api/add_comment', {'orderId': orderId, 'textComment': textComment})
            .catch(() => alert('Произошла ошибка'));
    },
}
