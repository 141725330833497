<template>
  <div class="popup">
    <form method="post" @submit.prevent="addComment" class="form" v-if="hasInput !== '0'">
      <input type="text" v-model="textComment" name="text" required>
      <input type="submit" class="add-comment" value="Добавить"/>
    </form>

    <ul>
      <li v-for="comment in comments" :key="comment.id" class="comment">
        {{ comment.comment }}
      </li>
    </ul>
  </div>
</template>

<script>
import api from '../../managerOrders/api/managerComments/index';

export default {
  name: 'WindowComments',
  data() {
    return {
      orderId: '',
      textComment: '',
      comments: [],
      hasInput: '',
    }
  },

  methods: {
    async addComment() {
      await api.addComment(this.orderId, this.textComment);
      this.comments  = await api.getComments(this.orderId);
      this.textComment = '';
    }
  },

  watch: {
    async orderId() {
      this.comments = await api.getComments(this.orderId);
    }
  }
};
</script>

<style scoped>
.popup{
  padding: 30px;
  background-color: #fff;
  border: 1px solid #333;
  box-shadow: 0 0 10px #333;
  border-radius: 10px;
  max-width: 700px;
}
.comment{
  font-size: 18px;
  padding: 10px 0 5px;
  border-bottom: 1px solid black;
}
.form{
  display: flex;
  gap: 10px;
  justify-content: center;
}
.add-comment{
  padding: 5px;
}
</style>
